import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GalaxyState {
  currentPlanetID?: string
  showInstructions: boolean
}

const initialState: GalaxyState = {
  showInstructions: true
}

export const galaxySlice = createSlice({
  name: 'galaxy',
  initialState,
  reducers: {
    setCurrentPlanetID: (state, action: PayloadAction<{id?: string}>) => {
      state.currentPlanetID = action.payload.id;
    },
    setShowInstructions: (state, action: PayloadAction<{show: boolean}>) => {
      state.showInstructions = action.payload.show;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentPlanetID, setShowInstructions } = galaxySlice.actions

export const galaxyReducer = galaxySlice.reducer;