exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galaxy-galaxy-tsx": () => import("./../../../src/pages/galaxy/galaxy.tsx" /* webpackChunkName: "component---src-pages-galaxy-galaxy-tsx" */),
  "component---src-pages-galaxy-index-tsx": () => import("./../../../src/pages/galaxy/index.tsx" /* webpackChunkName: "component---src-pages-galaxy-index-tsx" */),
  "component---src-pages-galaxy-star-tsx": () => import("./../../../src/pages/galaxy/star.tsx" /* webpackChunkName: "component---src-pages-galaxy-star-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shader-tsx": () => import("./../../../src/pages/shader.tsx" /* webpackChunkName: "component---src-pages-shader-tsx" */),
  "component---src-pages-skyway-index-tsx": () => import("./../../../src/pages/skyway/index.tsx" /* webpackChunkName: "component---src-pages-skyway-index-tsx" */),
  "component---src-pages-town-index-tsx": () => import("./../../../src/pages/town/index.tsx" /* webpackChunkName: "component---src-pages-town-index-tsx" */)
}

