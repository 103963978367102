import "@fontsource/varela-round";
import "./global.css";

import { gatsbyReduxWrapperBrowser } from "./src/store/store";
export const wrapRootElement = gatsbyReduxWrapperBrowser;

// document.addEventListener("readystatechange", e => {
//     console.log(`load: ${document.readyState}`);
// })

// window.addEventListener("load", () => {
//     console.log("loaded")
// })