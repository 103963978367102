import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airport, Flight } from '../services/skyway/ISkywayFlightService';

export interface SkywayState {
  departureAirport?: Airport,
  arrivalAirport?: Airport,
  selectedFlight?: Flight
}

const initialState: SkywayState = {
}

export const skywaySlice = createSlice({
  name: 'Skyway',
  initialState,
  reducers: {
    setDepartureAirport: (state, action: PayloadAction<{airport?: Airport}>) => {
      state.departureAirport = action.payload.airport;
    },
    setArrivalAirport: (state, action: PayloadAction<{airport?: Airport}>) => {
      state.arrivalAirport = action.payload.airport;
    },
    setSelectedFlight: (state, action: PayloadAction<{flight?: Flight}>) => {
      state.selectedFlight = action.payload.flight;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setArrivalAirport, setDepartureAirport, setSelectedFlight } = skywaySlice.actions

export const skywayReducer = skywaySlice.reducer;