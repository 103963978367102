import React from "react"
import { Provider } from "react-redux"
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { galaxyReducer } from "./galaxySlice";
import { skywayReducer } from "./skywaySlice";

const reducers = combineReducers({
  galaxy: galaxyReducer,
  skyway: skywayReducer
});

export const store = configureStore({
  reducer: reducers
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// eslint-disable-next-line react/display-name,react/prop-types
export const gatsbyReduxWrapperBrowser = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <Provider store={store}>
        {element}
    </Provider>
  );
}
export const gatsbyReduxWrapperSSR = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <Provider store={store}>
      {element}
    </Provider>
  );

}